import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Box, useMediaQuery } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  FooterGrid,
  NoResultsContainer,
  NoResultsHeader,
} from './newOrder.styles';
import ProductCard from './productCard';
import CatalogueActionTypes from '../../../providers/reducers/catalogue/catalogueTypes';
import { CLEARANCE_ORDER_PRODUCT, NEW_ORDER_PRODUCTS_PAGE_QUERY } from '../../../queries/products';
import { useStateValue } from '../../../providers/stateProvider';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import TablePagination from '../../shared/tablePagination';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const NewOrder = ({
  state,
  productId,
  active
}) => {
  const {
    searchValue, priceMin,
    priceMax, therapeuticTypeIds, availabilityStatus, manufacturer
  } = state;

  const handleAlertOpen = (msg) => toast(msg);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [pageCount, setPageCount] = useSearchParamsState('pc', isSmall ? '10' : '20');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const [, dispatch] = Object.values(useStateValue());

  const updateCatalogueState = (products, newOrderPageProductsTotalNumber) => {
    dispatch({
      type: CatalogueActionTypes.UPDATE_CATALOGUE,
      payload: {
        products,
        totalCount: newOrderPageProductsTotalNumber
      }
    });
  };

  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;
  const variables = {
    search: searchValue,
    therapeuticTypeIds,
    manufacturer,
    priceMin: priceMin ? parseFloat(priceMin) : null,
    priceMax: priceMax ? parseFloat(priceMax) : null,
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    availabilityStatus: (availabilityStatus === 'readilyAvailable') ? 'true' : (availabilityStatus === 'onRequest') ? 'false' : '',
    productId,
    topSelling: active === 'topSellingProducts',
    affiliateId: selectedAffiliate && _selectedAffiliate?.id
  };

  const { loading, data, refetch } = useQuery(NEW_ORDER_PRODUCTS_PAGE_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const [getClearanceProducts, { data: clearanceData, loading: clearanceLoading }] = useLazyQuery(
    CLEARANCE_ORDER_PRODUCT,
    {
      fetchPolicy: 'no-cache',
      variables
    }
  );

  useEffect(() => {
    if (active === 'clearanceSale') {
      getClearanceProducts();
    }
  }, [active]);

  useEffect(() => {
    if (data && data.newOrderPageProducts) {
      const { newOrderPageProducts: products, newOrderPageProductsTotalNumber } = data;
      updateCatalogueState(products, newOrderPageProductsTotalNumber);
    }
  }, [data]);

  const { newOrderPageProducts: products = [], newOrderPageProductsTotalNumber = 0 } = data || {};

  const { clearanceOrderPageProducts: clearanceProducts = {}, clearanceOrderPageProductsTotalNumber = 0 } = clearanceData || {};

  const productsData = active === 'clearanceSale' ? clearanceProducts : products;
  const productTotalNumber = active === 'clearanceSale' ? clearanceOrderPageProductsTotalNumber : newOrderPageProductsTotalNumber;
  const dataLoading = active === 'clearanceSale' ? clearanceLoading : loading;

  return (
    dataLoading ? <NewOrdersLoader />
      : (
        <Box style={{ marginTop: '1rem' }}>
          <ProductCardsContainer container spacing={3}>
            {productsData.length
              ? productsData.map((product, index) => (
                <ProductCardGrid key={product.id} item lg={3} md={12} xs={12}>
                  <ProductCard
                    product={product}
                    openAlert={handleAlertOpen}
                    index={index}
                    refetch={refetch}
                    active={active}
                  />
                </ProductCardGrid>
              )) : (
                <NoResultsContainer>
                  <NoResultsHeader>
                    Products Not Found!
                  </NoResultsHeader>
                  <NoResultsText>
                    Please we currently don’t have the product you searched for,
                    check out our recommended products below
                  </NoResultsText>
                </NoResultsContainer>
              )}
          </ProductCardsContainer>
          <FooterGrid item container className="joy-new-order-pagination">
            <TablePagination
              total={productTotalNumber}
              pageCount={+pageCount}
              setPageCount={setPageCount}
              pageNumber={+pageNumber}
              setPageNumber={setPageNumber}
              hideRowsPerPage
              displayShowResult
            />
          </FooterGrid>
        </Box>
      )
  );
};

NewOrder.propTypes = {
  state: PropTypes.instanceOf(Object),
  productId: PropTypes.number,
  active: PropTypes.string
};

NewOrder.defaultProps = {
  state: {},
  productId: 0,
  active: ''
};

export default NewOrder;
